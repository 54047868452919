import React from 'react';
import { withRouter } from 'react-router-dom';

import './SchoolCardSelection.scss';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ReactGA from 'react-ga';
import SmileyInfo from '../../components/smileyInfo/SmileyInfo';

@inject('questionnaireStore', 'topBarStore', 'mobileStore', 'swipeStore')
@observer
class SchoolCardSelection extends React.Component {
	handleButton(questionnaireName) {
		const { history, questionnaireStore } = this.props;

		questionnaireStore.setCurrent(questionnaireName);
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({ category: questionnaireName, action: 'Siirry korttiin', label: 'Korttivalinta' });

		history.push('/main/questionnaire');
	}

	handleBackToSave = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Takaisin',
			label: 'Korttivalinta'
		});
		this.props.history.push('/main/cardsave');
	};

	componentDidMount = () => {
		const { topBarStore } = this.props;
		topBarStore.setTopBarTitle('Koululaisen digikortit');
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillMount = () => {
		document.title = 'Kortin valinta';
	};

	render() {
		const { t } = this.props;
		return (
			<div className="schoolCardSelection neuvokasPaper">
				<img className="cardSelectionImage" src={require('./peliEdited.png')} alt="" />

				<div className="neuvokasText">{t('common.schoolCardSelectionText1')}</div>
				<div className="neuvokasText">{t('common.schoolCardSelectionText2')}</div>
				<SmileyInfo></SmileyInfo>

				<Button className="neuvokasButton starButton" onClick={() => this.handleButton('koululainen')}>
					Aloita tähtien kerääminen
				</Button>
				<div className="neuvokasText">{t('common.schoolCardSelectionText3')}</div>
				<Button className="neuvokasButton" onClick={() => this.handleButton('koululainenAikuinen')}>
					Koululaisen vanhemman digikortti
				</Button>
			</div>
		);
	}
}

export default withRouter(withTranslation()(SchoolCardSelection));
