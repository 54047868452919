import { observable, action } from 'mobx';

export class TopBarStore {
	@observable
	topBarTitle = 'Tervetuloa';

	@observable
	topBarColor = '';

	@action
	setTopBarTitle(newTitle) {
		this.topBarTitle = newTitle;
	}

	getTopBarTitle() {
		return this.topBarTitle;
	}

	// green yellow red
	@action
	setTopBarColor(newColor) {
		this.topBarColor = 'topBarColorId' + newColor;
	}

	@action
	resetTopBarColor() {
		this.topBarColor = '';
	}

	getTopBarColor() {
		return this.topBarColor;
	}
}

const topBarStore = new TopBarStore();
export default topBarStore;
