import { observable, action } from 'mobx';

export class DrawerStore {
	@observable
	open = false;

	@observable
	dialogOpen = false;

	@observable
	sideInfoVisible = false;

	@action
	setDrawer(open) {
		this.open = open;
	}

	isOpen() {
		return this.open;
	}

	@action
	setDialogState(state) {
		this.open = state;
	}

	isDialogOpen() {
		return this.dialogOpen;
	}

	@action
	setSideInfoState(state) {
		this.sideInfoVisible = state;
	}

	isSideInfoVisible() {
		return this.sideInfoVisible;
	}
}

const drawerStore = new DrawerStore();
export default drawerStore;
