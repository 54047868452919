import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './AccessabilityModal.scss';

@inject('dialogStore')
@observer
class AccessabilityModal extends React.Component {
	handleClose = () => {
		this.props.dialogStore.setAccessabilityModalState(false);
	};

	render() {
		const { dialogStore, t } = this.props;

		return (
			<Dialog
				className="accessabilityModalDialog"
				open={dialogStore.isAccessabilityModalOpen()}
				onClose={this.handleClose}
				maxWidth={false}
				aria-labelledby="simple-dialog-title"
				PaperProps={{ className: 'dialogPaperWrapper' }}>
				<div className="dialogTitleWrapper">
					<span className="dialogTitleText">{t('accessabilityText.title1')}</span>
					<IconButton className="closeButton" aria-label="Delete" onClick={this.handleClose}>
						<CloseIcon />
					</IconButton>
				</div>

				<div className="accessabilityTextWrapper">
					<p>{t('accessabilityText.p1')}</p>
				</div>
			</Dialog>
		);
	}
}
export default withTranslation()(AccessabilityModal);
