import React from 'react';

import { withTranslation } from 'react-i18next';

import './ColorInfo.scss';

import { observer, inject } from 'mobx-react';
import Balls from '../balls/Balls';

@inject('mobileStore')
@observer
class ColorInfo extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<div className="colorInfo">
				<div className="neuvokasText">
					<div>
						<div className="colorTitleWrapper">
							<Balls color="green" className="ball" />
							{t('common.greenChoice')}
						</div>
						<div className="colorDescription">{t('common.greenChoiceText')}</div>
					</div>

					<div>
						<div className="colorTitleWrapper">
							<Balls color="yellow" className="ball" />
							{t('common.yellowChoice')}
						</div>
						<div className="colorDescription">{t('common.yellowChoiceText')}</div>
					</div>
					<div>
						<div className="colorTitleWrapper">
							<Balls color="red" className="ball" />
							{t('common.redChoice')}
						</div>
						<div className="colorDescription">{t('common.redChoiceText')}</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withTranslation()(ColorInfo);
