import React from 'react';

import './SideBar.scss';
import { inject, observer } from 'mobx-react';

import { withTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import { action, toJS } from 'mobx';
import ReactGA from 'react-ga';

@inject('topBarStore', 'dialogStore', 'localizationStore', 'questionnaireStore', 'drawerStore', 'swipeStore')
@observer
class SideBar extends React.Component {
	@action
	handleClick = questionnaireName => {
		const { questionnaireStore } = this.props;
		questionnaireStore.setCurrent(questionnaireName);
		this.props.drawerStore.setDrawer(false);
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Siirry korttiin',
			label: 'Menu'
		});
		this.props.history.push('/main/questionnaire');
	};

	@action
	handleLegalClick = () => {
		ReactGA.event({ category: 'Menu', action: 'Siirry', label: 'Käyttöehdot' });
		this.props.dialogStore.setLegalModalState(true);
	};

	@action
	handleAccessabilityClick = () => {
		ReactGA.event({ category: 'Menu', action: 'Siirry', label: 'Saavutettavuusseloste' });
		this.props.dialogStore.setAccessabilityModalState(true);
	};

	handleFrontPage = () => {
		this.props.drawerStore.setDrawer(false);
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({ category: 'Menu', action: 'Siirry', label: 'Etusivu' });
		this.props.history.push('/main/beginning');
	};

	handleSchoolCard = () => {
		this.props.drawerStore.setDrawer(false);
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({ category: 'Menu', action: 'Siirry', label: 'Koululaisen digikortit' });
		this.props.history.push('/main/schoolCardselection');
	};

	handleColorInformation = () => {
		ReactGA.event({ category: 'Menu', action: 'Siirry', label: 'Käyttöohje' });
		this.props.dialogStore.setDialogState(true);
	};

	render() {
		const { questionnaireStore } = this.props;

		return (
			<div className="sideBar">
				<div>
					<div className="logoWrapper">
						<img className="drawerLogo" src={require('./neuvokasukkeli.png')} alt="neuvokasimage" />
					</div>

					<List className="navigationList" />
					<Divider className="navigationListDivider" />
					<List className="navigationList">
						<ListItem classes={{ root: 'menuItem' }} button key={'frontpageListItem'} onClick={this.handleFrontPage}>
							<ListItemText disableTypography>Etusivu</ListItemText>
						</ListItem>
						{questionnaireStore.getQuestionnaires().map(questionnaire => {
							if (toJS(questionnaire.id) !== 'koululainen' && toJS(questionnaire.id) !== 'koululainenAikuinen') {
								return (
									<ListItem
										className="listItem"
										classes={{ root: 'menuItem' }}
										button
										key={questionnaire.id + 'listItem'}
										onClick={() => this.handleClick(questionnaire.id)}>
										<ListItemText disableTypography>{questionnaire.full}</ListItemText>
									</ListItem>
								);
							}
							return null;
						})}
						<ListItem
							className="listItem"
							classes={{ root: 'menuItem' }}
							button
							key={'koululaisenkortitItem'}
							onClick={this.handleSchoolCard}>
							<ListItemText disableTypography>Koululaisen digikortit</ListItemText>
						</ListItem>
					</List>
					<Divider className="navigationListDivider" />
					<List className="navigationList">
						<ListItem
							className="listItem"
							classes={{ root: 'menuItem' }}
							button
							key={'colorCodingListItem'}
							onClick={this.handleColorInformation}>
							<ListItemText disableTypography>Täyttöohje</ListItemText>
						</ListItem>
						<ListItem classes={{ root: 'menuItem' }} button key={'legalListItem'} onClick={this.handleLegalClick}>
							<ListItemText disableTypography>Rekisteriseloste</ListItemText>
						</ListItem>
						<ListItem
							className="listItem"
							classes={{ root: 'menuItem' }}
							button
							key={'accessibilityListItem'}
							onClick={this.handleAccessabilityClick}>
							<ListItemText disableTypography>Saavutettavuusseloste</ListItemText>
						</ListItem>
					</List>
					<Divider className="navigationListDivider" />
				</div>

				<div className="logoWrapper">
					<img className="neuvokasLogo" src={require('./Neuvokas_ensisijainen_logo6_rgb_w.png')} alt="sydänliitto" />
				</div>
			</div>
		);
	}
}
export default withRouter(withTranslation()(SideBar));
