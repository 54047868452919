import React from 'react';

import { withRouter } from 'react-router-dom';

import './CardSave.scss';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga';

@inject('questionnaireStore', 'topBarStore', 'pdfStore', 'mobileStore')
@observer
class CardSave extends React.Component {
	state = {
		loading: false
	};

	toggleLoading = () => {
		this.setState({ loading: this.state.loading ? false : true });
	};

	handleCardSelectionButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Eteenpäin',
			label: 'Kortin tallennus'
		});
		this.props.history.push('/main/cardselection');
	};
	handleBackToFeedBack = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Takaisin',
			label: 'Kortin tallennus'
		});
		this.props.history.push('/main/questionnaire-feedback');
	};

	handleSaveButton = event => {
		this.props.history.push('/main/cardsave');
	};
	printDocument = event => {
		var pdfConverter = require('jspdf');
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Tallenna pdf',
			label: 'Palaute'
		});

		const imageUrls = this.props.pdfStore.getUrls();

		let pdf = new pdfConverter('portrait', 'mm', 'a4', true);

		imageUrls.forEach(function(imageUrl, i) {
			//var height = width * hRatios[i];
			//pdf.addImage(imageUrl, 0, 0, width, height);
			//pdf.addImage(imageUrl, 0, position, width, height);
			//heightLeft -= pageHeight;

			var imgWidth = pdf.internal.pageSize.width;
			var pageHeight = 295;
			var imgHeight = imgWidth * imageUrl.hratio;
			var heightLeft = imgHeight;
			var position = 0;
			pdf.addImage(imageUrl.url, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
			heightLeft -= pageHeight;
			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				pdf.addPage();
				pdf.addImage(imageUrl.url, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
				heightLeft -= pageHeight;
			}

			if (i < imageUrls.length - 1) {
				pdf.addPage();
			}
		});
		pdf.save('neuvokasperhe.pdf');
		//this.props.history.push('/main/cardselection');
	};

	componentDidMount = () => {
		const { topBarStore } = this.props;

		topBarStore.setTopBarTitle('Kortin tallennus');
	};

	render() {
		const { mobileStore, t } = this.props;

		return (
			<div className={mobileStore.isMobile() ? 'cardSave' : 'cardSave neuvokasPaper'}>
				{!this.props.mobileStore.isMobile() && <img className="cardSaveImage" src={require('./metsassa.jpg')} alt="" />}

				<div className="descriptionWrapper neuvokasText">{t('common.continueToOtherCard')}</div>

				<div className="neuvokasButtonWrapper">
					<div className="backAndSave">
						<Button className="neuvokasButton backButton" onClick={this.handleBackToFeedBack}>
							{t('common.back')}
						</Button>
						<Button className="neuvokasButton cardSelectionButton" onClick={this.handleCardSelectionButton}>
							{t('common.toCardChoice')}
						</Button>
					</div>

					<Button className="neuvokasButton saveButton" onClick={this.printDocument}>
						{t('common.save')}
						{this.state.loading && <CircularProgress className="circularProgress" size={20} />}
					</Button>
				</div>
				{this.props.mobileStore.isMobile() && (
					<img className="cardSaveImageMobile" src={require('./metsassa.jpg')} alt="" />
				)}
			</div>
		);
	}
}

export default withRouter(withTranslation()(CardSave));
