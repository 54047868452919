import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'fi',

		// have a common namespace used around the full app
		ns: ['common'],
		defaultNS: 'common',

		debug: false,

		interpolation: {
			escapeValue: false // not needed for react!!
		},

		react: {
			wait: true
		},

		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json'
		}
	});

export default i18n;
