import React from 'react';
import { withRouter } from 'react-router-dom';
import ChoiceQuestion from '../choiceQuestion/ChoiceQuestion';
import OpenQuestion from '../openQuestion/OpenQuestion';

import './SectionWrapper.scss';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { action } from 'mobx';
import NameSelector from '../nameSelector/NameSelector';
import i18n from 'i18next';

@inject('mobileStore', 'questionnaireStore')
@observer
class SectionWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = { name: '', age: '' };
	}

	@action
	handleChange = (param, question) => e => {
		if (param === 'age') {
			if (e.target.value > 18 || e.target.value < 0) return;
		}
		this.setState({ [param]: e.target.value });

		question.nameAndAge[param] = e.target.value;
	};
	render() {
		const { section, mobileStore, t, questionnaireStore } = this.props;

		const childAnswers = section.sectionId === 'koululainen.0' || section.sectionId === 'koululainen.2';
		return (
			<div className="sectionWrapper">
				<div className="sectionTitle">
					{i18n.exists('questions.' + section.sectionId + '.title') && t('questions.' + section.sectionId + '.title')}
				</div>
				{questionnaireStore.isAdultQuestionnaire() && section.sectionId === 'aikuinen.0' && (
					<div className="adultNameWrapper ">
						{questionnaireStore.getNames('aikuinen').map((name, index) => {
							return (
								<div key={name + index} className={'nameItem index' + index}>
									{name.text}
								</div>
							);
						})}
					</div>
				)}
				{section.questions.map((question, index) => {
					if (question.type === 'choice') {
						return (
							<div key={question.questionId} className="questionWrapper">
								<ChoiceQuestion
									key={question.questionId}
									question={question}
									questionIndex={index}
									questionCount={section.questions.length}
									childAnswers={childAnswers}
								/>
							</div>
						);
					} else if (question.type === 'openText') {
						return (
							<div key={question.questionId} className="questionWrapper">
								<OpenQuestion
									key={question.questionId}
									question={question}
									questionIndex={index}
									questionCount={section.questions.length}
								/>
							</div>
						);
					} else if (question.type === 'nameAndAge') {
						return (
							<div key={question.questionId} className="questionWrapper">
								<div
									className={
										mobileStore.isMobile() ? 'ageAndNameContainer neuvokasPaperMobile' : 'ageAndNameContainer'
									}>
									<TextField
										id="child-name"
										label="Nimi"
										className="text-field-name"
										value={question.nameAndAge.name}
										onChange={this.handleChange('name', question)}
										margin="normal"
										type="text"
										InputLabelProps={
											mobileStore.isMobile()
												? {
														className: 'neuvokasQuestion',
														shrink: true,
														classes: {
															root: 'cssLabel'
														}
												  }
												: {
														className: 'neuvokasQuestion',
														classes: {
															root: 'cssLabel'
														}
												  }
										}
										InputProps={{
											className: 'textInput'
										}}
									/>
									<TextField
										id="child-age"
										label="Ikä"
										className="text-field-age"
										value={question.nameAndAge.age}
										onChange={this.handleChange('age', question)}
										margin="normal"
										type="number"
										InputLabelProps={
											mobileStore.isMobile()
												? {
														className: 'neuvokasQuestion',
														shrink: true
												  }
												: { className: 'neuvokasQuestion' }
										}
										InputProps={{
											className: 'textInput',
											inputProps: { min: '0', max: '18' }
										}}
									/>
								</div>
							</div>
						);
					} else if (question.type === 'multipleNames') {
						return (
							<div key={question.questionId} className="questionWrapper">
								<NameSelector
									key={question.questionId}
									question={question}
									questionIndex={index}
									questionCount={section.questions.length}
								/>
							</div>
						);
					} else {
						return null;
					}
				})}
			</div>
		);
	}
}

export default withRouter(withTranslation()(SectionWrapper));
