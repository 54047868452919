import React from 'react';

import './TopBar.scss';
import { inject, observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { observable, action } from 'mobx';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
@inject('topBarStore', 'drawerStore', 'localizationStore')
@observer
class TopBar extends React.Component {
	@observable
	anchorEl = null;

	handleMenuButton = event => {
		const { drawerStore } = this.props;
		drawerStore.setDrawer(true);
	};

	@action
	handleClick = event => {
		this.anchorEl = event.currentTarget;
	};

	@action
	handleClose = () => {
		this.anchorEl = null;
	};
	@action
	handleChange = value => event => {
		const { localizationStore } = this.props;
		this.anchorEl = null;
		ReactGA.event({
			category: 'Käyttöliittymän kieli',
			action: 'Kielen vaihto',
			label: value
		});
		localizationStore.changeLanguage(value);
	};

	render() {
		const { topBarStore, t } = this.props;
		return (
			<div className={'topBar ' + topBarStore.getTopBarColor()}>
				<div className="buttonWrapper">
					<IconButton
						aria-label="Valikko"
						className={'topBarButton ' + topBarStore.getTopBarColor()}
						onClick={this.handleMenuButton}>
						<MenuIcon />
					</IconButton>
				</div>
				<h1 className="neuvokasTopBarTitle">{t(topBarStore.getTopBarTitle())}</h1>

				<div className="languageMenu">
					<div className="buttonWrapper">
						{/*<IconButton
							className={'languageIcon ' + topBarStore.getTopBarColor()}
							aria-owns={this.anchorEl ? 'simple-menu' : undefined}
							aria-haspopup="true"
							onClick={this.handleClick}>
							<LanguageIcon />
						</IconButton>*/}
					</div>
					<Menu
						disableAutoFocusItem
						id="simple-menu"
						anchorEl={this.anchorEl}
						open={Boolean(this.anchorEl)}
						onClose={this.handleClose}
						onChange={this.handleChange}>
						<MenuItem classes={{ root: 'languageItem' }} onClick={this.handleChange('fi')}>
							{t('languages.fi')}
						</MenuItem>
						<MenuItem classes={{ root: 'languageItem' }} onClick={this.handleChange('sv')}>
							{t('languages.sv')}
						</MenuItem>
					</Menu>
				</div>
			</div>
		);
	}
}
export default withTranslation()(TopBar);
