import React from 'react';

import { withTranslation } from 'react-i18next';

import './SideInstructions.scss';
import SmileyInfo from '../../components/smileyInfo/SmileyInfo';

import { observer, inject } from 'mobx-react';
import ColorInfo from '../colorInfo/ColorInfo';

@inject('mobileStore')
@observer
class SideInstructions extends React.Component {
	render() {
		return (
			<div className="sideInstructions">
				<h3>Aikuinen</h3>
				<div className="colorInfoWrapper">
					<ColorInfo />
				</div>
				<h3>Koululainen</h3>
				<SmileyInfo></SmileyInfo>
			</div>
		);
	}
}
export default withTranslation()(SideInstructions);
