import React from 'react';
import { withTranslation } from 'react-i18next';
import './ChoiceQuestion.scss';
import { observer, inject } from 'mobx-react';
import ChoiceRadio from '../choiceRadio/ChoiceRadio';
import { TextField } from '@material-ui/core';
import { has } from 'lodash';
import { toJS, action } from 'mobx';

/* eslint-disable */
@inject('mobileStore', 'dialogStore', 'swipeStore', 'questionnaireStore')
@observer
class ChoiceQuestion extends React.Component {
	handleColorDialog = () => {
		this.props.dialogStore.setDialogState(true);
	};

	@action
	handleFollowUpChange = e => {
		this.props.question.followUpAnswer = e.target.value;
	};

	handleMultipleFollowUpChange = (index, e) => {
		this.props.question.followUpAnswer[index] = e.target.value;
	};

	render() {
		const { t, question, mobileStore, questionCount, questionIndex, questionnaireStore, childAnswers } = this.props;
		/** Tän vois tabletissa näyttää
		 * 	<IconButton className="questionMarkButton" aria-label="show info" onClick={this.handleColorDialog}>
							<div className="questionMark">?</div>
						</IconButton>
		 */
		const adultNames = questionnaireStore.getNames();
		return (
			<div className={adultNames.length > 0 ? 'choiceQuestion adult' : 'choiceQuestion'}>
				<div
					className={
						mobileStore.isMobile()
							? adultNames.length > 0
								? 'cardContainer neuvokasPaperMobile adult'
								: 'cardContainer neuvokasPaperMobile'
							: 'cardContainer'
					}>
					<div className="numberWrapper" tabIndex="0">
						{mobileStore.isMobile() && t('common.question') + ' '}
						{questionIndex + 1} / {questionCount}
					</div>
					<div className="questionTextWrapper" tabIndex="0">
						{t('questions.' + question.questionId)}
					</div>
					<div className="radioWrapper">
						{Array.isArray(toJS(question.selectedId)) &&
							question.selectedId.map((selected, index) => {
								return (
									<div key={index} className="multipleRadioContainer">
										{mobileStore.isMobile() && <div className="adultNameWrapper">{adultNames[index].text}</div>}
										<div className="radioContainerWrapper">
											<ChoiceRadio
												question={question}
												index={index}
												selectedId={selected}
												questionId={question.questionId}
											/>
											{question.selectedId.length - 1 > index && <span className="radioDivider" />}
										</div>
									</div>
								);
							})}
						{!Array.isArray(toJS(question.selectedId)) && (
							<ChoiceRadio
								question={question}
								selectedId={question.selectedId}
								questionId={question.questionId}
								childAnswers={childAnswers}
							/>
						)}
					</div>
				</div>

				{has(question, 'followUpQuestion') &&
					toJS(question.selectedId) !== '' &&
					!questionnaireStore.isAdultQuestionnaire() && (
						<div className="followUpQuestionWrapper">
							<div className="followUpQuestion">{t('questions.' + question.followUpQuestion)}</div>{' '}
							<TextField
								className="followUpTextField"
								onChange={this.handleFollowUpChange}
								value={question.followUpAnswer}
							/>
						</div>
					)}
				{has(question, 'followUpQuestion') &&
					toJS(question.selectedId) !== '' &&
					questionnaireStore.isAdultQuestionnaire() &&
					adultNames.map((name, index) => {
						return (
							toJS(question.selectedId[index]) !== '' && (
								<div className="multipleFollowUpQuestionWrapper">
									{name.text.toUpperCase() + ': '}
									<div className="multipleFollowUpQuestion">{t('questions.' + question.followUpQuestion)}</div>{' '}
									<TextField
										className="multipleFollowUpTextField"
										onChange={e => this.handleMultipleFollowUpChange(index, e)}
										value={question.followUpAnswer[index]}
									/>
								</div>
							)
						);
					})}
			</div>
		);
	}
}
export default withTranslation()(ChoiceQuestion);
