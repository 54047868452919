import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import './StartInfoView.scss';
import { observable, decorate } from 'mobx';
import { inject, observer } from 'mobx-react';
import Instructions from '../../components/instructions/Instructions';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

@inject('mobileStore', 'swipeStore', 'drawerStore', 'questionnaireStore', 'topBarStore')
@observer
class StartInfoView extends React.Component {
	pageId = 0;

	handleButton = event => {
		this.props.history.push('/main/questionnaire/');
		ReactGA.event({ category: 'Meidän Perheen Kortti', action: 'Siirry korttiin', label: 'Etusivu' });
	};

	componentDidMount = () => {
		this.props.swipeStore.resetSwipeIndex();
		this.props.questionnaireStore.setCurrent('meidanperhe');
		this.props.topBarStore.resetTopBarColor();
	};

	render() {
		const { mobileStore, t } = this.props;

		return (
			<div className={mobileStore.isMobile() ? 'startInfoView ' : 'startInfoView neuvokasPaper'}>
				{!mobileStore.isMobile() && <img className="startImage" src={require('./Lautapeli Annika_0.jpg')} alt="" />}

				<div className="instructionsWrapper">
					<Instructions />
				</div>
				<div className="buttonWrapper">
					<Button className="neuvokasButton" variant="contained" onClick={this.handleButton}>
						{t('common.start')}
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(decorate(withTranslation()(StartInfoView), { pageId: observable }));
