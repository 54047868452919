import React from 'react';

import { withTranslation } from 'react-i18next';

import './SmileyInfo.scss';

import { observer, inject } from 'mobx-react';
import StarRatings from 'react-star-ratings';

@inject('mobileStore')
@observer
class SmileyInfo extends React.Component {
	render() {
		return (
			<div className="smileyInfo">
				<div className="starRow">
					<StarRatings
						rating={3}
						starRatedColor="rgb(255, 194, 0)"
						changeRating={null}
						numberOfStars={3}
						name="rating"
						starHoverColor="rgb(255, 88, 0)"
						starDimension="40px"
					/>
					<span>= usein</span>
				</div>
				<div className="starRow">
					<StarRatings
						rating={2}
						starRatedColor="rgb(255, 194, 0)"
						changeRating={null}
						numberOfStars={2}
						name="rating"
						starHoverColor="rgb(255, 88, 0)"
						starDimension="40px"
					/>
					<span>= joskus</span>
				</div>

				<div className="starRow">
					<StarRatings
						rating={1}
						starRatedColor="rgb(255, 194, 0)"
						changeRating={null}
						numberOfStars={1}
						name="rating"
						starHoverColor="rgb(255, 88, 0)"
						starDimension="40px"
					/>
					<span>= harvoin</span>
				</div>
			</div>
		);
	}
}
export default withTranslation()(SmileyInfo);
