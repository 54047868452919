import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './LegalModal.scss';

@inject('dialogStore')
@observer
class LegalModal extends React.Component {
	handleClose = () => {
		this.props.dialogStore.setLegalModalState(false);
	};

	render() {
		const { dialogStore, t } = this.props;

		return (
			<Dialog
				className="legalModalDialog"
				open={dialogStore.isLegalModalOpen()}
				onClose={this.handleClose}
				maxWidth={false}
				aria-labelledby="simple-dialog-title"
				PaperProps={{ className: 'dialogPaperWrapper' }}>
				<div className="dialogTitleWrapper">
					<span className="dialogTitleText">{t('cookieText.cookie-title1')}</span>
					<IconButton className="closeButton" aria-label="Delete" onClick={this.handleClose}>
						<CloseIcon />
					</IconButton>
				</div>

				<div className="cookieTextWrapper">
					<p>{t('cookieText.cookie-p1')}</p>
					<p>{t('cookieText.cookie-p2')}</p>
					<p>{t('cookieText.cookie-p3')}</p>
					<p>{t('cookieText.cookie-p4')}</p>
					<div className="cookieTitle">{t('cookieText.cookie-title2')}</div>
					<p>{t('cookieText.cookie-p5')}</p>
					<p>{t('cookieText.cookie-p6')}</p>
					<div className="cookieTitle">{t('cookieText.cookie-title3')}</div>
					<p>{t('cookieText.cookie-p7')}</p>
					<div className="cookieTitle">{t('cookieText.cookie-title4')}</div>
					<p>{t('cookieText.cookie-p8')}</p>
					<p>{t('cookieText.cookie-p9')}</p>
					<p>{t('cookieText.cookie-p10')}</p>
					<p>{t('cookieText.cookie-p11')}</p>
				</div>
			</Dialog>
		);
	}
}
export default withTranslation()(LegalModal);
