import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import OpenQuestion from '../../components/openQuestion/OpenQuestion';
import ChoiceQuestion from '../../components/choiceQuestion/ChoiceQuestion';
import SectionWrapper from '../../components/sectionWrapper/SectionWrapper';
import { withTranslation } from 'react-i18next';

import './QuestionnaireView.scss';
import { observer, inject } from 'mobx-react';
import { observable, action, toJS } from 'mobx';
import SwipeableViews from 'react-swipeable-views';
import NameSelector from '../../components/nameSelector/NameSelector';
import TextField from '@material-ui/core/TextField';
import ReactGA from 'react-ga';

@inject('questionnaireStore', 'swipeStore', 'mobileStore', 'topBarStore', 'pdfStore', 'drawerStore')
@observer
class QuestionnaireView extends React.Component {
	@observable
	width = 0;

	@observable
	reactSwipeEl;

	@action
	handleChange = (param, question) => e => {
		if (param === 'age') {
			if (e.target.value > 18 || e.target.value < 0) return;
		}
		this.setState({ [param]: e.target.value });

		question.nameAndAge[param] = e.target.value;
	};

	handleButton = event => {
		const { history, questionnaireStore } = this.props;

		questionnaireStore.setCompleted();
		ReactGA.event({ category: questionnaireStore.getCurrentQuestionnaireFull(), action: 'Valmis', label: 'Menu' });
		if (questionnaireStore.getCurrent() === 'koululainen') {
			history.push('/main/schoolResults');
		} else {
			history.push('/main/questionnaire-feedback');
		}
	};

	handleChangeIndex = index => {
		this.props.swipeStore.setSwipeIndex(index);
	};

	componentDidMount = () => {
		this.props.topBarStore.setTopBarTitle(this.props.questionnaireStore.getCurrentQuestionnaireFull());
		this.props.drawerStore.setSideInfoState(true);
		this.props.topBarStore.resetTopBarColor();
	};

	componentWillUpdate = () => {
		this.props.topBarStore.setTopBarTitle(this.props.questionnaireStore.getCurrentQuestionnaireFull());
	};

	componentWillMount = () => {
		document.title = this.props.questionnaireStore.getCurrentQuestionnaireFull();
	};

	componentWillUnmount = () => {
		this.props.drawerStore.setSideInfoState(false);
	};

	MoveBetweenSections = () => {
		const { swipeStore, mobileStore, questionnaireStore } = this.props;

		return (
			mobileStore.isMobile() && (
				<div className="swipeButtonContainer">
					<div className="leftButtonWrapper">
						{swipeStore.getSwipeIndex() !== 0 && (
							<Button className="swipeButtonLeft" variant="outlined" onClick={() => swipeStore.decreaseSwipeIndex()}>
								Edellinen
							</Button>
						)}
						{swipeStore.getSwipeIndex() === 0 && <div className="swipeButton" />}
					</div>
					<div className="leftButtonWrapper">
						{swipeStore.getSwipeIndex() <
							questionnaireStore.getAllQuestionnaireQuestions(questionnaireStore.getCurrent()).length && (
							<Button className="swipeButtonRight" variant="outlined" onClick={() => swipeStore.increaseSwipeIndex()}>
								Seuraava
							</Button>
						)}
					</div>
				</div>
			)
		);
	};

	render() {
		const { questionnaireStore, swipeStore, mobileStore, t } = this.props;
		let count = -1;
		const questionsAmount = questionnaireStore.getChoiceQuestionnaireQuestions(questionnaireStore.getCurrent()).length;
		if (mobileStore.isMobile()) {
			return (
				<div className="questionnaireWrapperMobile">
					<SwipeableViews
						style={{}}
						index={swipeStore.getSwipeIndex()}
						onChangeIndex={this.handleChangeIndex}
						springConfig={{ duration: '0.4s', easeFunction: 'ease', delay: '0s' }}>
						{questionnaireStore
							.getAllQuestionnaireQuestionsWithSections(questionnaireStore.getCurrent())
							.map(questionSection => {
								return questionSection.questions.map((question, index) => {
									if (toJS(question.type) === 'choice') {
										count += 1;

										return (
											<div key={question.questionId} className="questionWrapper">
												<ChoiceQuestion
													question={question}
													questionCount={questionsAmount}
													questionIndex={count}
													childAnswers={questionnaireStore.checkForChildQuestions(questionSection.id)}
												/>
											</div>
										);
									} else if (question.type === 'openText') {
										return (
											<div key={question.questionId} className="questionWrapperMobile">
												<OpenQuestion question={question} questionCount={questionsAmount} questionIndex={count} />
											</div>
										);
									} else if (question.type === 'nameAndAge') {
										return (
											<div key={question.questionId} className="questionWrapper">
												<div
													className={
														mobileStore.isMobile() ? 'ageAndNameContainer neuvokasPaperMobile' : 'ageAndNameContainer'
													}>
													<div className="nameContainer">Koululaisen tiedot</div>
													<div className="textfieldContainer">
														<TextField
															id="child-name"
															label="Nimi"
															className="text-field-name"
															value={question.nameAndAge.name}
															onChange={this.handleChange('name', question)}
															margin="normal"
															type="text"
															InputLabelProps={
																mobileStore.isMobile()
																	? {
																			className: 'neuvokasQuestion',
																			shrink: true,
																			classes: {
																				root: 'cssLabel'
																			}
																	  }
																	: {
																			className: 'neuvokasQuestion',
																			classes: {
																				root: 'cssLabel'
																			}
																	  }
															}
															InputProps={{
																className: 'textInput'
															}}
														/>
														<TextField
															id="child-age-mobile"
															label="Ikä"
															className="text-field-age"
															value={question.nameAndAge.age}
															onChange={this.handleChange('age', question)}
															margin="normal"
															type="number"
															InputLabelProps={
																mobileStore.isMobile()
																	? {
																			className: 'neuvokasQuestion',
																			shrink: true
																	  }
																	: { className: 'neuvokasQuestion' }
															}
															InputProps={{
																className: 'textInput',
																inputProps: { min: '0', max: '18' }
															}}
														/>
													</div>
												</div>
											</div>
										);
									} else if (question.type === 'multipleNames') {
										return (
											<div key={question.questionId} className="questionWrapper">
												<div className="adultNamesContainer">
													<NameSelector
														key={question.questionId}
														question={question}
														questionIndex={count}
														questionCount={questionsAmount}
													/>
												</div>
											</div>
										);
									}
									return null;
								});
							})}
						<div className="panelWrapper">
							<div className="panel neuvokasPaperMobile">
								<div>{t('common.thanksForAnswering')}</div>
								<Button className="endQuestionnaireButton neuvokasButton" onClick={this.handleButton}>
									{t('common.ready')}
								</Button>
							</div>
						</div>
					</SwipeableViews>
					<div> {this.MoveBetweenSections()}</div>
				</div>
			);
		} else {
			return (
				<div className="questionnaireWrapper ">
					{questionnaireStore.getAllQuestionnairesBySection(questionnaireStore.getCurrent()).map(section => {
						return (
							<div key={section.sectionId} className="sectionContainer">
								<SectionWrapper section={section} />
							</div>
						);
					})}
					<div className="endQuestionnaireButtonWrapper">
						<Button className="endQuestionnaireButton neuvokasButton" onClick={this.handleButton}>
							{t('common.ready')}
						</Button>
					</div>
				</div>
			);
		}
	}
}

export default withRouter(withTranslation()(QuestionnaireView));
