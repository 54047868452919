import React from 'react';
import { withRouter } from 'react-router-dom';

import './CardSelection.scss';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ReactGA from 'react-ga';

@inject('questionnaireStore', 'topBarStore', 'mobileStore', 'swipeStore')
@observer
class CardSelection extends React.Component {
	handleButton(questionnaireName) {
		const { history, questionnaireStore } = this.props;

		questionnaireStore.setCurrent(questionnaireName);
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({ category: questionnaireName, action: 'Siirry korttiin', label: 'Korttivalinta' });

		history.push('/main/questionnaire');
	}

	handleBackToSave = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Takaisin',
			label: 'Korttivalinta'
		});
		this.props.history.push('/main/cardsave');
	};

	handleSchoolButton = event => {
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({
			category: this.props.questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Siirry',
			label: 'Koululaisen digikortit'
		});
		this.props.history.push('/main/schoolCardselection');
	};

	componentDidMount = () => {
		const { topBarStore } = this.props;
		topBarStore.setTopBarTitle('Kortin valinta');
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillMount = () => {
		document.title = 'Kortin valinta';
	};

	render() {
		const { questionnaireStore, t } = this.props;
		return (
			<div className="cardSelection neuvokasPaper">
				<img className="cardSelectionImage" src={require('./kotisirkus_787px.jpg')} alt="" />

				<div className="neuvokasText">{t('common.nextCardToChooceText')}</div>
				<div className="neuvokasText">{t('common.schoolCardInfoText')}</div>
				<div className="buttonContainer">
					<div className="buttonWrapper">
						{questionnaireStore.getQuestionnaires().map(
							questionnaire =>
								questionnaire.id !== 'meidanperhe' &&
								questionnaire.id !== 'koululainen' &&
								questionnaire.id !== 'koululainenAikuinen' && (
									<div key={'cardselection_' + questionnaire.full}>
										<Button className="neuvokasButton" onClick={() => this.handleButton(questionnaire.id)}>
											{questionnaire.full}
										</Button>
									</div>
								)
						)}{' '}
						<Button className="neuvokasButton" onClick={this.handleSchoolButton}>
							Koululaisen digikortit
						</Button>
					</div>
					<Button className="neuvokasButton backButton" onClick={this.handleBackToSave}>
						{t('common.back')}
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(CardSelection));
