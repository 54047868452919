import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './InfoModal.scss';

@inject('dialogStore')
@observer
class InfoModal extends React.Component {
	kuva3 = require('./kuvat/3.jpg');
	kuva5 = require('./kuvat/5.jpg');
	kuva6 = require('./kuvat/6.jpg');
	kuva7 = require('./kuvat/7.jpg');
	kuva8 = require('./kuvat/8.jpg');
	kuva9 = require('./kuvat/9.jpg');
	kuva10 = require('./kuvat/10.jpg');
	kuva11 = require('./kuvat/11.jpg');
	kuva12 = require('./kuvat/12.jpg');
	kuva13 = require('./kuvat/13.jpg');
	kuva15 = require('./kuvat/15.jpg');
	kuva16 = require('./kuvat/16.jpg');
	kuva17 = require('./kuvat/17.jpg');
	kuva18 = require('./kuvat/18.jpg');
	kuva20 = require('./kuvat/20.jpg');
	kuva24 = require('./kuvat/24.jpg');
	kuva26 = require('./kuvat/26.jpg');
	kuva27 = require('./kuvat/27.jpg');
	kuva28 = require('./kuvat/28.jpg');
	kuva29 = require('./kuvat/29.jpg');
	kuva34 = require('./kuvat/34.jpg');
	kuva35 = require('./kuvat/35.jpg');
	kuva36 = require('./kuvat/36.jpg');
	kuva38 = require('./kuvat/38.jpg');
	kuva39 = require('./kuvat/39.jpg');
	kuva41 = require('./kuvat/41.jpg');
	kuva44 = require('./kuvat/44.jpg');
	kuva45 = require('./kuvat/45.jpg');
	kuva46 = require('./kuvat/46.jpg');
	kuva48 = require('./kuvat/48.jpg');
	kuva49 = require('./kuvat/49.jpg');
	kuva53 = require('./kuvat/53.jpg');

	images = [
		require('./arki.png'),
		require('./kokataan.jpg'),
		require('./pyora.jpg'),
		require('./hyppynaru.jpg'),
		require('./unta.jpg'),
		require('./ystavyys.jpg')
	];
	handleClose = () => {
		this.images = [];
		this.props.dialogStore.setInfoModalState(false);
	};

	componentDidUpdate = () => {
		this.images = [
			require('./arki.png'),
			require('./kokataan.jpg'),
			require('./pyora.jpg'),
			require('./hyppynaru.jpg'),
			require('./unta.jpg'),
			require('./ystavyys.jpg')
		];
	};

	render() {
		const { dialogStore, t } = this.props;

		return (
			<Dialog
				className="extraInfoDialog"
				open={dialogStore.isInfoModalOpen()}
				onClose={this.handleClose}
				maxWidth={false}
				aria-labelledby="simple-dialog-title"
				PaperProps={{ className: 'dialogPaperWrapper' }}>
				<div className="dialogTitleWrapper">
					<div className="dialogTitleText">{t('common.extraInformation')}</div>
					<IconButton className="closeButton" aria-label="Delete" onClick={this.handleClose}>
						<CloseIcon />
					</IconButton>
				</div>

				<div className="infoWrapper">
					<div className="title neuvokasHeadline" />
					{dialogStore.infoModalText === 'kuva' && (
						<img className="kuva" alt="Lisätieto kuva" src={this[dialogStore.infoModalLink]}></img>
					)}
					{dialogStore.infoModalText !== 'kuva' && (
						<div className="infoContainer">
							<img className="startImage" src={this.images[Math.floor(Math.random() * this.images.length)]} alt="" />

							<div className="infoTextWrapper neuvokasText">{dialogStore.infoModalText}</div>
							<div className="infoLinkWrapper neuvokasText">
								<a target="_blank" rel="noopener noreferrer" href={dialogStore.infoModalLink} title="">
									{t('common.linkText')}
								</a>
							</div>
						</div>
					)}
				</div>
			</Dialog>
		);
	}
}
export default withTranslation()(InfoModal);
