import React from 'react';
import StarRatings from 'react-star-ratings';

import './StudentSmileys.scss';
import { observer } from 'mobx-react';
@observer
class StudentSmileys extends React.Component {
	changeRating(newRating, name) {
		this.setState({
			rating: newRating
		});
	}

	render() {
		return (
			<StarRatings
				rating={1}
				starRatedColor="yellow"
				changeRating={this.changeRating}
				numberOfStars={3}
				name="rating"
			/>
		);
	}
}
export default StudentSmileys;
