import { observable, action, toJS } from 'mobx';
import { sumBy, filter, sum, get } from 'lodash';

export class QuestionnaireStore {
	@observable
	questionnaires = [
		{ id: 'meidanperhe', full: 'Meidän perheen digikortti' },
		{ id: 'neuvola', full: 'Neuvolaikäisen digikortti' },
		{ id: 'koululainen', full: 'Koululaisen digikortti' },
		{ id: 'koululainenAikuinen', full: 'Koululaisen vanhemman digikortti' },
		{ id: 'aikuinen', full: 'Aikuisen digikortti' }
	];

	@observable
	currentQuestionnaire = 'meidanperhe';

	@observable
	meidanperhe = [];

	@observable
	meidanperheCompleted = false;

	@observable
	koululainen = [];
	koululainenCompleted = false;
	@observable
	koululainenAikuinen = [];
	koululainenAikuinenCompleted = false;

	@observable
	aikuinen = [];
	aikuinenCompleted = false;

	@observable
	neuvola = [];
	neuvolaCompleted = false;

	@observable
	sideBarContent = [];

	@action
	setNumberOfAdults(direction, index) {
		if (direction === 'up') {
			this.aikuinen.forEach(section =>
				section.questions.forEach(question => {
					question.selectedId && question.selectedId.push('');
				})
			);
		} else if (direction === 'down') {
			this.aikuinen.forEach(section =>
				section.questions.forEach(question => {
					if (question.selectedId) {
						question.selectedId.splice(index, 1);
					}
				})
			);
		}
	}

	@action
	setSideBarContent(content) {
		this.sideBarContent.push(content);
	}

	@action
	setCompleted() {
		this[this.currentQuestionnaire + 'Completed'] = true;
	}

	@action
	addQuestinnaires(questionnaire) {
		var jsonData = JSON.parse(questionnaire);
		this.meidanperhe = jsonData.questionnaire.meidanperhe;
		this.koululainen = jsonData.questionnaire.koululainen;
		this.koululainenAikuinen = jsonData.questionnaire.koululainenAikuinen;
		this.neuvola = jsonData.questionnaire.neuvola;
		this.aikuinen = jsonData.questionnaire.aikuinen;
	}

	getCurrent() {
		return this.currentQuestionnaire;
	}

	getCurrentQuestionnaireFull() {
		return this.questionnaires.find(questionnaire => questionnaire.id === this.currentQuestionnaire).full;
	}

	setCurrent(questionnaireName) {
		this.currentQuestionnaire = questionnaireName;
	}

	isCompleted(questionnaire) {
		return this[questionnaire + 'Completed'];
	}

	getQuestionnaires() {
		return this.questionnaires;
	}

	getAnswerCount(questionnaire, answerId) {
		let count;
		if (this.currentQuestionnaire === 'aikuinen') {
			count = this.getNames('aikuinen').map((iter, index) => {
				let tempSum = sum(
					toJS(this[questionnaire]).map(section => {
						return sumBy(section['questions'], ({ selectedId }) => {
							if (!selectedId) return Number(0);
							return selectedId[index] === answerId ? Number(1) : Number(0);
						});
					})
				);
				return tempSum;
			});
		} else {
			count = sum(
				toJS(this[questionnaire]).map(section => {
					return sumBy(section['questions'], ({ selectedId }) => {
						return selectedId === answerId ? Number(1) : Number(0);
					});
				})
			);
		}

		return count;
	}

	getStarCount() {
		let count = 0;
		count = count + this.getAnswerCount('koululainen', '1');
		count = count + this.getAnswerCount('koululainen', '2') * 2;
		count = count + this.getAnswerCount('koululainen', '3') * 3;
		return count;
	}

	getAnswersByColor(questionnaire, colorId, index) {
		let tempArray = [];

		if (this.currentQuestionnaire === 'aikuinen') {
			this[questionnaire].forEach(section => {
				const questions = filter(toJS(section['questions']), function(question) {
					return get(question, 'selectedId') && toJS(question.selectedId[index]) === colorId;
				});
				if (questions.length > 0) {
					tempArray.push({
						sectionId: section.sectionId,
						questions: questions
					});
				}
			});
		} else {
			this[questionnaire].forEach(section => {
				const questions = filter(toJS(section['questions']), { selectedId: colorId });
				if (questions.length > 0) {
					tempArray.push({
						sectionId: section.sectionId,
						questions: questions
					});
				}
			});
		}
		return tempArray;
	}

	getOpenAnswers(questionnaire) {
		let tempArray = [];

		this[questionnaire].forEach(section => {
			const openQuestions = filter(toJS(section['questions']), { type: 'openText' });
			if (openQuestions.length > 0) {
				tempArray.push({
					sectionId: section.sectionId,
					openQuestions: openQuestions
				});
			}
		});

		return tempArray;
	}

	getDetails(questionnaire) {
		let tempArray = filter(toJS(this[questionnaire]), { sectionId: questionnaire + '.99' });
		if (tempArray.length > 0) return tempArray[0].questions[0].nameAndAge;
	}

	getNames(questionnaire = 'aikuinen') {
		let tempArray = filter(toJS(this[questionnaire]), { sectionId: questionnaire + '.99' });
		if (tempArray.length > 0) return tempArray[0].questions[0].answer;
		return [];
	}

	getAllQuestionnaireQuestions(questionnaire) {
		let tempQuestionnaires = [];
		this[questionnaire].forEach(section => {
			tempQuestionnaires = tempQuestionnaires.concat(...section.questions);
		});
		return tempQuestionnaires;
	}

	getChoiceQuestionnaireQuestions(questionnaire) {
		let tempQuestionnaires = [];
		this[questionnaire].forEach(section => {
			const choiceQuestions = filter(toJS(section['questions']), { type: 'choice' });
			tempQuestionnaires = tempQuestionnaires.concat(...choiceQuestions);
		});
		return tempQuestionnaires;
	}

	getAllQuestionnaireQuestionsWithSections(questionnaire) {
		let tempQuestionnaires = [];
		this[questionnaire].forEach(section => {
			tempQuestionnaires.push({ id: section.sectionId, questions: section.questions });
		});
		return tempQuestionnaires;
	}

	checkForChildQuestions(questionnaireSection) {
		return questionnaireSection === 'koululainen.0' || questionnaireSection === 'koululainen.2';
	}

	getAllQuestionnairesBySection(questionnaire) {
		let tempQuestionnaires = [];
		this[questionnaire].forEach(section => {
			tempQuestionnaires.push(section);
		});
		return tempQuestionnaires;
	}

	isVisibleDetails() {
		return this.currentQuestionnaire === 'neuvola' || this.currentQuestionnaire === 'koululainen';
	}

	isAdultQuestionnaire() {
		return this.currentQuestionnaire === 'aikuinen';
	}

	isFamilyQuestionnaire() {
		return this.currentQuestionnaire === 'meidanperhe';
	}
	isStudentQuestionnaire() {
		return this.currentQuestionnaire === 'koululainen';
	}
	isStudentParentQuestionnaire() {
		return this.currentQuestionnaire === 'koululainenAikuinen';
	}
}

const questionnaireStore = new QuestionnaireStore();
export default questionnaireStore;
