import React from 'react';
import { withRouter } from 'react-router-dom';

import './SchoolHero.scss';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ReactGA from 'react-ga';

@inject('questionnaireStore', 'topBarStore', 'mobileStore', 'swipeStore')
@observer
class SchoolHero extends React.Component {
	handleButton(questionnaireName) {
		const { history, questionnaireStore } = this.props;

		questionnaireStore.setCurrent(questionnaireName);
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({ category: questionnaireName, action: 'Siirry korttiin', label: 'Korttivalinta' });

		history.push('/main/questionnaire');
	}

	handleCardSelectButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Eteenpäin',
			label: 'Supersankari'
		});
		this.props.history.push('/main/cardsave');
	};
	handleBackButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Takaisin',
			label: 'Tulokset'
		});
		this.props.history.push('/main/schoolResults');
	};

	handleNextCardButton = event => {
		const { questionnaireStore } = this.props;
		questionnaireStore.setCurrent('koululainenAikuinen');

		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Siirry korttiin',
			label: 'Koululaisen digikortti'
		});
		this.props.history.push('/main/questionnaire');
	};

	componentDidMount = () => {
		const { topBarStore } = this.props;
		topBarStore.setTopBarTitle('Koululaisen digikortit');
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillMount = () => {
		document.title = 'Kortin valinta';
	};

	render() {
		const { t } = this.props;
		return (
			<div className="schoolHero neuvokasPaper">
				<div className="neuvokasText">Kurkkaa videosta, mistä keho tykkää ja mitkä jutut tekee sille hyvää!</div>
				<div className="neuvokasText">
					Supersankarikehosta kannattaa tykätä, niin kuin hyvästä kaverista. Sillä siitä mistä tykkää, pitää myös hyvää
					huolta.
				</div>
				<iframe
					className="videoIframe"
					title="Sankari"
					src="https://www.youtube.com/embed/VtYWyUyNCF8"
					allow="accelerometer; autoplay; encrypted-media; gyroscope"
					allowfullscreen="true"></iframe>

				<div className="neuvokasText">
					Koululaisen vanhemmat: voitte vastata koululaista koskeviin kysymyksiinne tästä. Aikuisen omat kysymykset
					löydätte aikuisen digikortista.
				</div>
				<div className="buttonWrapper">
					<Button
						className="backButton neuvokasButton"
						variant="contained"
						color="primary"
						onClick={this.handleBackButton}>
						{t('common.back')}
					</Button>
					<div>
						<Button onClick={this.handleNextCardButton} className="neuvokasButton neuvokasWideButton">
							Koululaisen vanhemman digikortti
						</Button>
						<Button className="neuvokasButton forwardButton" onClick={this.handleCardSelectButton}>
							Eteenpäin
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(SchoolHero));
