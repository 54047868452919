import React from 'react';
import { withRouter } from 'react-router-dom';

import './SchoolResults.scss';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ReactGA from 'react-ga';
import html2canvas from 'html2canvas';

@inject('questionnaireStore', 'topBarStore', 'mobileStore', 'swipeStore', 'pdfStore')
@observer
class SchoolResults extends React.Component {
	handleButton() {
		const { history } = this.props;

		history.push('/main/schoolHero');
	}

	handleBackToQuestionsButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({ category: questionnaireStore.getCurrentQuestionnaireFull(), action: 'Takaisin', label: 'Palaute' });

		this.props.history.push('/main/questionnaire');
	};

	handlePrintPdf = () => {
		const { pdfStore, questionnaireStore } = this.props;

		const elem = document.getElementById('printSchoolPdf');

		html2canvas(elem, { allowTaint: true, logging: false }).then(canvas => {
			pdfStore.addUrl(
				questionnaireStore.getCurrent(),
				canvas.toDataURL('image/png', canvas.width, canvas.height),
				canvas.height / canvas.width
			);
		});
	};

	componentDidMount = () => {
		const { topBarStore } = this.props;
		topBarStore.setTopBarTitle('Koululaisen digikortit');
		this.handlePrintPdf();
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillMount = () => {
		document.title = 'Kortin valinta';
	};

	render() {
		const { questionnaireStore, t } = this.props;
		const openAnswers = toJS(questionnaireStore.getOpenAnswers(questionnaireStore.getCurrent()));

		return (
			<div className="schoolResults neuvokasPaper">
				<div className="neuvokasOtsikko">Vau, sait näin paljon tähtiä: sinulla on hienot supervoimat! </div>
				<div>
					<div className="summaryStarContainer">
						<div className="summaryStar">{questionnaireStore.getStarCount()}</div>{' '}
						<div className="starCountWrapper"></div>
					</div>
				</div>
				<div className=" openAnswerList">
					<div className="neuvokasTopBarTitle">{t('common.answersToOpenquestions')}</div>
					{openAnswers.map(openAnswerSection => {
						return (
							<div>
								<div className="neuvokasOtsikko">{t('questions.' + openAnswerSection.sectionId + '.title')}</div>
								{openAnswerSection.openQuestions.map(answer => {
									return (
										<div key={answer.questionId} className="openAnswerTextWrapper">
											<div className="questionTextWrapper questionItem">{t('questions.' + answer.questionId)}</div>
											<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
				<div className="buttonWrapper">
					<Button
						className="backButton neuvokasButton"
						variant="contained"
						color="primary"
						onClick={this.handleBackToQuestionsButton}>
						{t('common.back')}
					</Button>
					<Button className="neuvokasButton" onClick={() => this.handleButton()}>
						eteenpäin
					</Button>
				</div>
				<div id="printSchoolPdf" className="questionList">
					<div className="fullHeadline">{questionnaireStore.getCurrentQuestionnaireFull()}</div>
					<div className="neuvokasOtsikko">Vau, sait näin paljon tähtiä: sinulla on hienot supervoimat! </div>
					<div>
						<div className="summaryStarContainer">
							<div className="summaryStar">{questionnaireStore.getStarCount()}</div>{' '}
							<div className="starCountWrapper"></div>
						</div>
					</div>
					<div className=" openAnswerList">
						<div className="neuvokasTopBarTitle">{t('common.answersToOpenquestions')}</div>
						{openAnswers.map(openAnswerSection => {
							return (
								<div>
									<div className="neuvokasOtsikko">{t('questions.' + openAnswerSection.sectionId + '.title')}</div>
									{openAnswerSection.openQuestions.map(answer => {
										return (
											<div key={answer.questionId} className="openAnswerTextWrapper">
												<div className="questionTextWrapper questionItem">{t('questions.' + answer.questionId)}</div>
												<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>

					<div className="logoWrapper">
						<img className="logoLeft" src={require('./Neuvokas_ensisijainen_logo1_rgb_c.png')} alt="" />
						<img className="logoRight" src={require('./sl_logo_fi_rgb_c.png')} alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(SchoolResults));
