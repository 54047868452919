import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import './ChoiceRadio.scss';
import Balls from '../balls/Balls';
import StudentSmileys from '../studentSmileys/StudentSmileys';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import StarRatings from 'react-star-ratings';

@inject('swipeStore', 'questionnaireStore')
@observer
class ChoiceRadio extends React.Component {
	@action
	handleChangeSelected = selectedId => event => {
		this.handleChange(selectedId, event.target.value);
	};
	@action
	handleKeyPress = selectedId => e => {
		if (e.key === 'Enter' || e.key === ' ') {
			this.handleChange(selectedId, e.target.value);
		}
	};

	@action
	handleChange(selectedId, value) {
		const { swipeStore, question, index, questionnaireStore, t } = this.props;
		const colors = ['vihreä', 'keltainen', 'punainen'];
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Vastaus väitteeseen',
			label: t('questions.' + question.questionId) + ' Vastaus: ' + colors[value - 1]
		});
		if (index !== undefined) {
			if (question.selectedId[index] === value) {
				question.selectedId[index] = '';
			} else {
				question.selectedId[index] = value;
			}
		} else {
			if (question.selectedId === value) {
				question.selectedId = '';
			} else {
				question.selectedId = value;
			}
		}
		if (!questionnaireStore.isAdultQuestionnaire()) {
			swipeStore.increaseSwipeIndex();
		}
	}

	@action
	changeRating = (newRating, name) => {
		const { question } = this.props;
		question.selectedId = newRating.toString();
	};

	render() {
		const { selectedId, questionId, index, questionnaireStore, t, childAnswers } = this.props;

		// 							<span className="checkedMark" />
		return (
			<div className="choiceRadio">
				{!childAnswers ? (
					<form
						action=""
						className={
							questionnaireStore.isAdultQuestionnaire() ? 'choiceRadioForm verticalButtons' : 'choiceRadioForm'
						}>
						<label
							htmlFor={questionId + index + 'green'}
							className="container"
							onKeyPress={this.handleKeyPress(selectedId)}>
							<span className="radioHiddenName">{t('common.greenChoiceText')}</span>
							<input
								tabIndex="0"
								id={questionId + index + 'green'}
								type="radio"
								name="gender"
								value="1"
								readOnly
								checked={selectedId === '1'}
								onClick={this.handleChangeSelected(selectedId)}
							/>
							{childAnswers ? (
								<StudentSmileys color="green" className="hoverable" selected={selectedId === '1'} />
							) : (
								<Balls color="green" className="hoverable" selected={selectedId === '1'} />
							)}{' '}
						</label>

						<label
							htmlFor={questionId + index + 'yellow'}
							className="container"
							onKeyPress={this.handleKeyPress(selectedId)}>
							<span className="radioHiddenName">{t('common.yellowChoiceText')}</span>
							<input
								tabIndex="0"
								id={questionId + index + 'yellow'}
								type="radio"
								name="gender"
								value="2"
								readOnly
								checked={selectedId === '2'}
								onClick={this.handleChangeSelected(selectedId)}
							/>
							{childAnswers ? (
								<StudentSmileys color="yellow" className="hoverable" selected={selectedId === '2'} />
							) : (
								<Balls color="yellow" className="hoverable" selected={selectedId === '2'} />
							)}{' '}
						</label>
						<label
							htmlFor={questionId + index + 'red'}
							className="container"
							onKeyPress={this.handleKeyPress(selectedId)}>
							<span className="radioHiddenName">{t('common.redChoiceText')}</span>
							<input
								tabIndex="0"
								id={questionId + index + 'red'}
								type="radio"
								name="gender"
								value="3"
								readOnly
								checked={selectedId === '3'}
								onClick={this.handleChangeSelected(selectedId)}
							/>
							{childAnswers ? (
								<StudentSmileys color="red" className="hoverable" selected={selectedId === '3'} />
							) : (
								<Balls color="red" className="hoverable" selected={selectedId === '3'} />
							)}
						</label>
					</form>
				) : (
					<div className="starRatingWrapper">
						<StarRatings
							rating={Number(selectedId)}
							starRatedColor="rgb(255, 194, 0)"
							changeRating={this.changeRating}
							numberOfStars={3}
							name="rating"
							starHoverColor="rgb(255, 88, 0)"
							starDimension="40px"
						/>
					</div>
				)}
			</div>
		);
	}
}
export default withTranslation()(ChoiceRadio);
