import React from 'react';

import { withTranslation } from 'react-i18next';

import './ScrollingSideInfo.scss';

import { observer, inject } from 'mobx-react';

import ColorInfo from '../colorInfo/ColorInfo';
import SmileyInfo from '../smileyInfo/SmileyInfo';

@inject('dialogStore', 'questionnaireStore')
@observer
class ScrollingSideInfo extends React.Component {
	handleClose = () => {
		this.props.dialogStore.setDialogState(false);
	};

	render() {
		const { questionnaireStore } = this.props;

		return (
			<div className="sideInfoContainer">
				{questionnaireStore.isStudentQuestionnaire() && (
					<div className="scrollingSmileySideInfo">
						<SmileyInfo />
					</div>
				)}
				{!questionnaireStore.isStudentQuestionnaire() && (
					<div className="scrollingSideInfo">
						<ColorInfo />
					</div>
				)}
			</div>
		);
	}
}
export default withTranslation()(ScrollingSideInfo);
