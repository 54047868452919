import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import './MainView.scss';
import StartInfoView from '../startInfoView/StartInfoView';
import TopBar from '../../components/topBar/TopBar';

import FeedbackView from '../feedbackView/FeedbackView';
import CardSelection from '../cardSelection/CardSelection';
import CardSave from '../cardSave/CardSave';

import Drawer from '@material-ui/core/Drawer';

import { inject, observer } from 'mobx-react';
import QuestionnaireView from '../questionnaireView/QuestionnaireView';
import SideBar from '../../components/sideBar/SideBar';
import ModalPopper from '../../components/modalPopper/ModalPopper';
import { Prompt } from 'react-router';
import { withTranslation } from 'react-i18next';
import ScrollingSideInfo from '../../components/scrollingSideInfo/ScrollingSideInfo';
import InfoModal from '../../components/infoModal/InfoModal';
import LegalModal from '../../components/legalModal/LegalModal';
import AccessabilityModal from '../../components/accessabilityModal/AccessabilityModal';

import SchoolCardSelection from '../SchoolCardSelection/SchoolCardSelection';
import SchoolResults from '../SchoolResults/SchoolResults';
import SchoolHero from '../SchoolHero/SchoolHero';

@inject('drawerStore', 'mobileStore')
@observer
class MainView extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef(); // Create a ref object
	}
	handleButton = event => {
		this.props.history.push('/before/');
	};

	toggleDrawer = open => () => {
		this.props.drawerStore.setDrawer(open);
	};

	componentWillMount() {
		this.handleWindowSizeChange();
		window.addEventListener('resize', this.handleWindowSizeChange);
		window.addEventListener('beforeunload', e => this.handleUnloadCheck(e));
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
		window.removeEventListener('beforeunload', this.handleUnloadCheck);
	}
	handleUnloadCheck = e => {
		e.preventDefault();
		e.returnValue = '';
		return null;
	};
	handleWindowSizeChange = () => {
		this.props.mobileStore.setWidth(window.innerWidth);
	};
	componentDidUpdate = () => {
		this.myRef.current.scrollTop = 0;
	};

	render() {
		const { drawerStore, t, mobileStore } = this.props;

		return (
			<div className="wrapperWrapper">
				<div className="topBarWrapper">
					<TopBar />
				</div>
				<div className="main-view" ref={this.myRef}>
					<Drawer classes={{ paper: 'sidebarMaterial' }} open={drawerStore.isOpen()} onClose={this.toggleDrawer(false)}>
						<div className="sideBarWrapper" tabIndex={0} role="button">
							<SideBar />
						</div>
					</Drawer>
					<ModalPopper />
					<InfoModal />
					<LegalModal />
					<AccessabilityModal />
					{!mobileStore.isMobile() ? (
						<div className="flexWrapper">
							<div className="leftSideWrapper" />
							<div className="questionnaireFlexWrapper">
								<Switch>
									<Redirect exact={true} from="/main" to="/main/beginning" />
									<Route path="/main/beginning" component={StartInfoView} />
									<Route path="/main/questionnaire" render={props => <QuestionnaireView {...props} />} />
									<Route path="/main/questionnaire-feedback" render={props => <FeedbackView {...props} />} />
									<Route path="/main/cardselection" component={CardSelection} />
									<Route path="/main/schoolCardselection" component={SchoolCardSelection} />
									<Route path="/main/cardsave" component={CardSave} />
									<Route path="/main/schoolResults" component={SchoolResults} />
									<Route path="/main/schoolHero" component={SchoolHero} />
								</Switch>
							</div>

							<div className="rightSideWrapper">{drawerStore.isSideInfoVisible() && <ScrollingSideInfo />}</div>
						</div>
					) : (
						<div className="questionnaireWrapWrapper">
							<Switch>
								<Redirect exact={true} from="/main" to="/main/beginning" />
								<Route path="/main/beginning" component={StartInfoView} />

								<Route path="/main/questionnaire" render={props => <QuestionnaireView {...props} />} />
								<Route path="/main/questionnaire-feedback" render={props => <FeedbackView {...props} />} />
								<Route path="/main/schoolCardselection" component={SchoolCardSelection} />

								<Route path="/main/cardselection" component={CardSelection} />
								<Route path="/main/cardsave" component={CardSave} />
								<Route path="/main/schoolResults" component={SchoolResults} />
								<Route path="/main/schoolHero" component={SchoolHero} />
							</Switch>
						</div>
					)}

					<React.Fragment>
						<Prompt when={true} message={t('beginning.changeSite')} />
					</React.Fragment>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(MainView));
