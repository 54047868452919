import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PieChart from 'react-minimal-pie-chart';

import './NeuvokasPieChart.scss';
import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';

@observer
class NeuvokasPieChart extends React.Component {
	@observable
	data = [
		{
			title: 'vihreät vastaukset',
			value: this.props.greenAnswerCount,
			color: '#7BBB7B',
			hoverColor: '#7BBB7BCC',
			id: '1'
		},
		{
			title: 'keltaiset vastaukset',
			value: this.props.yellowAnswerCount,
			color: '#FBCE7B',
			hoverColor: '#FBCE7BCC',
			id: '2'
		},
		{
			title: 'punaiset vastaukset',
			value: this.props.redAnswerCount,
			color: '#FB7B7B',
			hoverColor: '#FB7B7BCC',
			id: '3'
		}
	];

	tempColor = '';

	handleClick = (e, d, i) => {
		this.props.answerCallback(this.data[i].id);
	};

	@action
	handleOver = (e, d, i) => {
		this.tempColor = this.data[i].color;
		this.data[i].color = this.data[i].hoverColor;
	};
	@action
	handleOut = (e, d, i) => {
		this.data[i].color = this.tempColor;
	};

	render() {
		return (
			<div className="neuvokasPieChart">
				<Card className="kortti">
					<CardContent>
						{this.props.greenAnswerCount + this.props.yellowAnswerCount + this.props.redAnswerCount > 0 ? (
							<PieChart
								data={toJS(this.data)}
								onMouseOver={this.handleOver}
								onFocus={this.handleOver}
								onMouseOut={this.handleOut}
								onBlur={this.handleOut}
								onClick={this.handleClick}
								lengthAngle={-360}
								label
								labelStyle={{
									fontSize: '0.6em',
									fontFamily: 'Gerbera-light',
									fill: '#333'
								}}
							/>
						) : null}
					</CardContent>
				</Card>
			</div>
		);
	}
}
export default NeuvokasPieChart;
