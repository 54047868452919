import i18n from 'i18next';
import { observable, action, runInAction } from 'mobx';

export class LocalizationStore {
	@observable
	currentLanguage = '';
	@observable availableLanguages = ['fi', 'sv'];

	@action
	async changeLanguage(language) {
		try {
			await new Promise((resolve, reject) => {
				i18n.changeLanguage(language, error => {
					error ? reject(error) : resolve();
				});
			});

			runInAction(() => {
				this.currentLanguage = language;
			});
		} catch (error) {
			console.error('Changing language failed', error);
		}
	}
	getLanguage() {
		return this.currentLanguage;
	}
}

const localizationStore = new LocalizationStore();
export default localizationStore;
