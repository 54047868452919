import { observable, action } from 'mobx';
import { find, set } from 'lodash';

export class PdfStore {
	@observable
	imageUrls = [];

	@action
	addUrl(questionnaireName, imageUrl, hRatio) {
		let val = find(this.imageUrls, obj => obj.name === questionnaireName);
		if (val) {
			set(val, 'url', imageUrl);
			set(val, 'hratio', hRatio);
		} else {
			this.imageUrls.push({ name: questionnaireName, url: imageUrl, hratio: hRatio });
		}
	}

	getUrls() {
		return this.imageUrls;
	}
}

const pdfStore = new PdfStore();
export default pdfStore;
