import React from 'react';
import { withRouter } from 'react-router-dom';

import './FeedbackView.scss';
import { observer, inject } from 'mobx-react';
import { toJS, observable, action } from 'mobx';
import NeuvokasPieChart from '../../components/neuvokasPieChart/NeuvokasPieChart';
import { withTranslation } from 'react-i18next';
import { ListItem, List, Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import SwipeableViews from 'react-swipeable-views';
import { has } from 'lodash';
import Divider from '@material-ui/core/Divider';
import Balls from '../../components/balls/Balls';
import i18n from 'i18next';
import ReactGA from 'react-ga';

@inject('questionnaireStore', 'topBarStore', 'swipeStore', 'mobileStore', 'pdfStore', 'drawerStore', 'dialogStore')
@observer
class FeedbackView extends React.Component {
	@observable
	showPieChart = true;

	@observable
	selectedColorId = '';

	@observable
	selectedName = 0;

	@observable
	nameId = 0;

	@action
	answerCallback = (answer, nameId) => {
		const { questionnaireStore } = this.props;
		const colors = ['Vihreät vastaukset', 'Keltaiset vastaukset', 'Punaiset vastaukset'];
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Lisätiedot',
			label: colors[answer]
		});
		this.showPieChart = false;
		this.selectedColorId = answer;
		this.nameId = nameId;
	};

	@action
	handleChangeAdultIndex = index => {
		this.props.swipeStore.setAdultSwipeIndex(index);
	};

	@action
	handleCardSelectButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Eteenpäin',
			label: 'Palaute'
		});
		this.props.history.push('/main/cardsave');
	};

	@action
	handleStudentCardButton = event => {
		const { questionnaireStore } = this.props;
		questionnaireStore.setCurrent('koululainen');
		this.props.swipeStore.resetSwipeIndex();
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Siirry korttiin',
			label: 'Aikuisen digikortti'
		});
		this.props.history.push('/main/questionnaire');
	};

	@action
	handleClickInfoButton = questionId => {
		const { t } = this.props;
		this.props.dialogStore.setInfoModalState(
			true,
			t('info.' + questionId + '.text'),
			t('info.' + questionId + '.link')
		);
	};

	@action
	handleCardManualSwipe = event => {
		this.props.swipeStore.setSwipeIndex(1);
	};

	@action
	handleBackToQuestionsButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({ category: questionnaireStore.getCurrentQuestionnaireFull(), action: 'Takaisin', label: 'Palaute' });

		this.props.history.push('/main/questionnaire');
	};

	@action
	handleBackButton = event => {
		const { questionnaireStore } = this.props;
		ReactGA.event({
			category: questionnaireStore.getCurrentQuestionnaireFull(),
			action: 'Takaisin',
			label: 'Lisätiedot '
		});
		this.selectedColorId = '';
		this.showPieChart = true;
	};

	handlePrintPdf = () => {
		const { pdfStore, questionnaireStore } = this.props;

		const elem = document.getElementById('printPdf');

		html2canvas(elem, { allowTaint: true, logging: false }).then(canvas => {
			pdfStore.addUrl(
				questionnaireStore.getCurrent(),
				canvas.toDataURL('image/png', canvas.width, canvas.height),
				canvas.height / canvas.width
			);
		});
	};

	handleAnswerTableButton = answer => {
		this.answerCallback(answer);
	};

	componentDidMount = () => {
		this.showPieChart = true;
		this.props.topBarStore.setTopBarTitle('Loppupalaute');

		this.handlePrintPdf();
		this.props.drawerStore.setSideInfoState(true);
	};

	componentDidUpdate = () => {
		this.props.topBarStore.setTopBarColor(this.selectedColorId);
	};

	componentWillUnmount = () => {
		this.props.drawerStore.setSideInfoState(false);
	};

	countNullZero = count => {
		if (count === 0) return null;
		return count;
	};

	MoveBetweenSections = () => {
		const { swipeStore, mobileStore, questionnaireStore } = this.props;

		return (
			mobileStore.isMobile() && (
				<div className="swipeButtonContainer">
					<div className="leftButtonWrapper">
						{swipeStore.getAdultSwipeIndex() !== 0 && (
							<Button
								className="swipeButtonLeft"
								variant="outlined"
								onClick={() => swipeStore.decreaseAdultSwipeIndex()}>
								Edellinen
							</Button>
						)}
						{swipeStore.getSwipeIndex() === 0 && <div className="swipeButton" />}
					</div>
					<div className="leftButtonWrapper">
						{swipeStore.getAdultSwipeIndex() < questionnaireStore.getNames().length - 1 && (
							<Button
								className="swipeButtonRight"
								variant="outlined"
								onClick={() => swipeStore.increaseAdultSwipeIndex()}>
								Seuraava
							</Button>
						)}
					</div>
				</div>
			)
		);
	};

	render() {
		const { questionnaireStore, t, mobileStore, swipeStore } = this.props;
		let colorCount = [];
		colorCount.push(questionnaireStore.getAnswerCount(questionnaireStore.getCurrent(), '1'));
		colorCount.push(questionnaireStore.getAnswerCount(questionnaireStore.getCurrent(), '2'));
		colorCount.push(questionnaireStore.getAnswerCount(questionnaireStore.getCurrent(), '3'));
		const answersByColor = toJS(
			questionnaireStore.getAnswersByColor(questionnaireStore.getCurrent(), this.selectedColorId, this.nameId)
		);
		const openAnswers = toJS(questionnaireStore.getOpenAnswers(questionnaireStore.getCurrent()));
		const noAnswers = colorCount[0] === 0 && colorCount[1] === 0 && colorCount[2] === 0;
		const numbers = [{ name: 'vihreät', id: '1' }, { name: 'keltaiset', id: '2' }, { name: 'punaiset', id: '3' }];
		const count = ['1', '2', '3'];
		const details = questionnaireStore.getDetails(questionnaireStore.getCurrent());
		const adultNames = questionnaireStore.getNames(questionnaireStore.getCurrent());
		return (
			<div className="feedbackView">
				{this.showPieChart ? (
					<div className={mobileStore.isMobile() ? 'feedbackWrapper ' : 'feedbackWrapper neuvokasPaper'}>
						{mobileStore.isMobile() ? (
							<div className="descriptionWrapper neuvokasText">
								{t('common.thankYouForFillingTextMobile')}
								<div className="neuvokasTopBarTitle">{t('common.thankYouForFillingTextMobile2')}</div>
							</div>
						) : questionnaireStore.isAdultQuestionnaire() && adultNames.length < 1 ? (
							<div className="descriptionWrapper neuvokasText">{t('common.noAdultSelected')}</div>
						) : (
							<div className="descriptionWrapper neuvokasText">
								{noAnswers ? (
									t('common.noAnswersText')
								) : (
									<div>
										<div>{t('common.thankYouForFillingText')}</div>
										<div className="neuvokasTopBarTitle">{t('common.thankYouForFillingText2')}</div>
									</div>
								)}
							</div>
						)}

						{questionnaireStore.isVisibleDetails() && (
							<div className="ageAndName">
								<div>
									<span className="boldedText">Lapsen nimi: </span>
									{details.name || '-'}
								</div>
								<div>
									<span className="boldedText">Lapsen ikä: </span>
									{details.age || '-'}
								</div>
							</div>
						)}

						{questionnaireStore.isAdultQuestionnaire() && (
							<div className="multiplePieChartContainer">
								{mobileStore.isMobile() && (
									<div className="adultNameSwipeText">{t('common.swipeBetweenAdultAnswers')}</div>
								)}
								{mobileStore.isMobile() && (
									<div>
										<SwipeableViews
											className="adultswipeWrapper"
											style={{}}
											index={swipeStore.getAdultSwipeIndex()}
											onChangeIndex={this.handleChangeAdultIndex}
											springConfig={{ duration: '0.4s', easeFunction: 'ease', delay: '0s' }}>
											{adultNames.map((nameItem, index) => {
												return (
													<div key={'pie' + nameItem.id} className="pieChartAndNameContainer">
														<div>{nameItem.text}</div>
														<div className="multiplePieChartWrapper">
															<NeuvokasPieChart
																greenAnswerCount={colorCount[0][index] === 0 ? null : colorCount[0][index]}
																yellowAnswerCount={colorCount[1][index] === 0 ? null : colorCount[1][index]}
																redAnswerCount={colorCount[2][index] === 0 ? null : colorCount[2][index]}
																answerCallback={colorId => this.answerCallback(colorId, index)}
															/>
														</div>
														<div className="listAndAnswerWrapper">
															{!noAnswers && !mobileStore.isMobile() && (
																<ul className="answerButtonList">
																	<li className="listItemButton">
																		<Button
																			className="answerButton green"
																			variant="outlined"
																			classes={{ root: 'answerButton green' }}
																			onClick={() => this.answerCallback('1', index)}
																			fullWidth>
																			<Balls color="green" className="ball" /> {t('common.greenAnswers')}
																			{' ' + colorCount[0][index] || 0}
																		</Button>
																	</li>
																	<li className="listItemButton">
																		<Button
																			variant="outlined"
																			classes={{ root: 'answerButton yellow' }}
																			onClick={() => this.answerCallback('2', index)}>
																			<Balls color="yellow" className="ball" /> {t('common.yellowAnswers')}
																			{' ' + colorCount[1][index] || 0}
																		</Button>
																	</li>
																	<li className="listItemButton">
																		<Button
																			variant="outlined"
																			classes={{ root: 'answerButton red' }}
																			onClick={() => this.answerCallback('3', index)}>
																			<Balls color="red" className="ball" /> {t('common.redAnswers')}
																			{' ' + colorCount[2][index] || 0}
																		</Button>
																	</li>
																</ul>
															)}
														</div>
													</div>
												);
											})}
										</SwipeableViews>
										<div> {this.MoveBetweenSections()}</div>
									</div>
								)}
								{!mobileStore.isMobile() &&
									adultNames.map((nameItem, index) => {
										return (
											<div key={'pie' + nameItem.id} className="pieChartAndNameContainer">
												<div>{nameItem.text}</div>
												<div className="multiplePieChartWrapper">
													<NeuvokasPieChart
														greenAnswerCount={this.countNullZero(colorCount[0][index])}
														yellowAnswerCount={this.countNullZero(colorCount[1][index])}
														redAnswerCount={this.countNullZero(colorCount[2][index])}
														answerCallback={colorId => this.answerCallback(colorId, index)}
													/>
												</div>
												<div className="listAndAnswerWrapper">
													{!noAnswers && !mobileStore.isMobile() && (
														<ul className="answerButtonList">
															<li className="listItemButton">
																<Button
																	className="answerButton green"
																	variant="outlined"
																	classes={{ root: 'answerButton green' }}
																	onClick={() => this.answerCallback('1', index)}
																	fullWidth>
																	<Balls color="green" className="ball" /> {t('common.greenAnswers')}
																	{' ' + colorCount[0][index] || 0}
																</Button>
															</li>
															<li className="listItemButton">
																<Button
																	variant="outlined"
																	classes={{ root: 'answerButton yellow' }}
																	onClick={() => this.answerCallback('2', index)}>
																	<Balls color="yellow" className="ball" /> {t('common.yellowAnswers')}
																	{' ' + colorCount[1][index] || 0}
																</Button>
															</li>
															<li className="listItemButton">
																<Button
																	variant="outlined"
																	classes={{ root: 'answerButton red' }}
																	onClick={() => this.answerCallback('3', index)}>
																	<Balls color="red" className="ball" /> {t('common.redAnswers')}
																	{' ' + colorCount[2][index] || 0}
																</Button>
															</li>
														</ul>
													)}

													{openAnswers.map(answer => (
														<div key={answer.questionId}>
															<div className="questionTextWrapper questionItem">
																{t('questions.' + answer.questionId)}
															</div>
															<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
														</div>
													))}
												</div>
											</div>
										);
									})}
							</div>
						)}
						{!questionnaireStore.isAdultQuestionnaire() && (
							<div className="pieChartContainer">
								<div id="printPie" className="pieChartWrapper">
									<NeuvokasPieChart
										greenAnswerCount={this.countNullZero(colorCount[0])}
										yellowAnswerCount={this.countNullZero(colorCount[1])}
										redAnswerCount={this.countNullZero(colorCount[2])}
										answerCallback={this.answerCallback}
									/>
								</div>

								<div className="listAndAnswerWrapper">
									{!noAnswers && (
										<ul className="answerButtonList">
											<li className="listItemButton">
												<Button
													className="answerButton green"
													variant="outlined"
													classes={{ root: 'answerButton green' }}
													onClick={() => this.answerCallback('1')}
													fullWidth>
													<Balls color="green" className="ball" /> {t('common.greenAnswers')}
													{' ' + colorCount[0] || 0}
												</Button>
											</li>
											<li className="listItemButton">
												<Button
													variant="outlined"
													classes={{ root: 'answerButton yellow' }}
													onClick={() => this.answerCallback('2')}>
													<Balls color="yellow" className="ball" /> {t('common.yellowAnswers')}
													{' ' + colorCount[1] || 0}
												</Button>
											</li>
											<li className="listItemButton">
												<Button
													variant="outlined"
													classes={{ root: 'answerButton red' }}
													onClick={() => this.answerCallback('3')}>
													<Balls color="red" className="ball" /> {t('common.redAnswers')}
													{' ' + colorCount[2] || 0}
												</Button>
											</li>
										</ul>
									)}
									{openAnswers.length > 0 && (
										<div className=" openAnswerList">
											<div className="neuvokasTopBarTitle">{t('common.answersToOpenquestions')}</div>
											{openAnswers.map(openAnswerSection => {
												return (
													<div key={openAnswerSection.sectionId}>
														<div className="openQuestionSectionTitle">
															{t('questions.' + openAnswerSection.sectionId + '.title')}
														</div>
														{openAnswerSection.openQuestions.map(answer => {
															return (
																<div key={answer.questionId} className="openAnswerTextWrapper">
																	<div className="questionTextWrapper questionItem">
																		{t('questions.' + answer.questionId)}
																	</div>
																	<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
																</div>
															);
														})}
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						)}
						{questionnaireStore.isStudentParentQuestionnaire() && (
							<div className="buttonInstruction">Koululainen: vastaa omiin kysymyksiisi tästä.</div>
						)}
						<div className="neuvokasButtonWrapper buttonWrapper">
							<Button className="neuvokasButton backButton " onClick={this.handleBackToQuestionsButton}>
								{t('common.back')}
							</Button>
							{!questionnaireStore.isStudentParentQuestionnaire() && (
								<Button className="toCardSelectionButton neuvokasButton" onClick={this.handleCardSelectButton}>
									{t('common.forward')}
								</Button>
							)}
							{questionnaireStore.isStudentParentQuestionnaire() && (
								<div className="schooladultwrapper">
									<Button
										className="toParentCardButton neuvokasButton neuvokasWideButton"
										onClick={this.handleStudentCardButton}>
										Koululaisen digikortti
									</Button>
									<Button className="toCardSelectionButton neuvokasButton" onClick={this.handleCardSelectButton}>
										{t('common.forward')}
									</Button>
								</div>
							)}
						</div>
					</div>
				) : (
					<div className="questionList ">
						<div className="topInstructions">
							Tässä valitsemasi {this.selectedColorId === '1' && <span>vihreät </span>}
							{this.selectedColorId === '2' && <span>keltaiset </span>}
							{this.selectedColorId === '3' && <span>punaiset </span>}
							vastaukset.
							<IconButton color="primary" aria-label="show info">
								<InfoIcon className="infoIcon" />
							</IconButton>
							– napin takaa saat innostavia vinkkejä.
						</div>
						{questionnaireStore.isAdultQuestionnaire()
							? answersByColor.map(answersByColorSection => {
									return (
										<div className="sectionAnswerListContainer">
											{i18n.exists('questions.' + answersByColorSection.sectionId + '.title') && (
												<div className="sectionNameContainer">
													{t('questions.' + answersByColorSection.sectionId + '.title')}
												</div>
											)}
											<List className="answerList">
												{answersByColorSection.questions.map(answer => {
													return (
														<ListItem className={'listItem color-' + this.selectedColorId}>
															<div className="liContainer">
																{t('questions.' + answer.questionId)}
																{i18n.exists('info.' + answer.questionId + '.text') && (
																	<IconButton
																		color="primary"
																		aria-label="show info"
																		onClick={() => this.handleClickInfoButton(answer.questionId)}>
																		<InfoIcon className="infoIcon" />
																	</IconButton>
																)}
															</div>
															{has(answer, 'followUpAnswer') && answer.followUpAnswer[this.nameId] !== '' && (
																<div className="followUpContainer">
																	<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
																	<div>{answer.followUpAnswer[this.nameId]}</div>
																</div>
															)}
														</ListItem>
													);
												})}
											</List>
										</div>
									);
							  })
							: answersByColor.map(answersByColorSection => {
									return (
										<div
											key={'colorsectionan' + answersByColorSection.sectionId}
											className="sectionAnswerListContainer">
											{i18n.exists('questions.' + answersByColorSection.sectionId + '.title') && (
												<div className="sectionNameContainer">
													{t('questions.' + answersByColorSection.sectionId + '.title')}
												</div>
											)}
											<List className="answerList">
												{answersByColorSection.questions.map(answer => {
													return (
														<ListItem key={'answerid' + answer} className={'listItem color-' + this.selectedColorId}>
															<div className="liContainer">
																{t('questions.' + answer.questionId)}
																{i18n.exists('info.' + answer.questionId + '.text') && (
																	<IconButton
																		color="primary"
																		aria-label="show info"
																		onClick={() => this.handleClickInfoButton(answer.questionId)}>
																		<InfoIcon className="infoIcon" />
																	</IconButton>
																)}
															</div>
															{has(answer, 'followUpAnswer') && answer.followUpAnswer !== '' && (
																<div className="followUpContainer">
																	<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
																	<div>{answer.followUpAnswer}</div>
																</div>
															)}
														</ListItem>
													);
												})}
											</List>
										</div>
									);
							  })}
						<Divider />
						<div className="listButtonWrapper">
							<Button
								className="backButton neuvokasButton"
								variant="contained"
								color="primary"
								onClick={this.handleBackButton}>
								{t('common.back')}
							</Button>
						</div>
					</div>
				)}
				<div id="printPdf" className="questionList">
					<div className="fullHeadline">{questionnaireStore.getCurrentQuestionnaireFull()}</div>

					{questionnaireStore.isVisibleDetails() && (
						<div className="ageAndName">
							<div>
								{t('common.childName')} {details.name || '-'}
							</div>{' '}
							<div>
								{t('common.childAge')} {details.age || '-'}
							</div>
						</div>
					)}
					{questionnaireStore.isAdultQuestionnaire()
						? adultNames.map((nameItem, adultIndex) => {
								return (
									<div key={nameItem + adultIndex}>
										<div className="adultNameWrapper">Aikuisen nimi: {nameItem.text}</div>
										{count.map((colorId, index) => {
											return (
												<div key={colorId + index} className="listWrapper">
													{colorCount[index][adultIndex] !== 0 && (
														<div className="colorText">{numbers[index].name + ' vastaukset'}</div>
													)}

													<List className="answerList">
														{questionnaireStore
															.getAnswersByColor(questionnaireStore.getCurrent(), colorId, adultIndex)
															.map(sectionAnswers => {
																return (
																	<div>
																		<div className="sectionNameWrapper">
																			{t('questions.' + sectionAnswers.sectionId + '.title')}
																		</div>
																		{sectionAnswers.questions.map(answer => {
																			return (
																				<ListItem className={'color-' + colorId}>
																					<div className="liContainer">
																						<div className="questionTextContainer">
																							{t('questions.' + answer.questionId)}
																						</div>
																					</div>
																					{has(answer, 'followUpAnswer') && answer.followUpAnswer[adultIndex] !== '' && (
																						<div className="followUpContainer">
																							<div className="questionText">
																								{t('questions.' + answer.followUpQuestion)}
																							</div>
																							<div>{answer.followUpAnswer[adultIndex]}</div>
																						</div>
																					)}
																				</ListItem>
																			);
																		})}
																	</div>
																);
															})}
													</List>
												</div>
											);
										})}
									</div>
								);
						  })
						: count.map((colorId, index) => {
								return (
									<div key={colorId + index} className="listWrapper">
										{colorCount[index] !== 0 && <div className="colorText">{numbers[index].name + ' vastaukset'}</div>}
										<List className="answerList">
											{questionnaireStore
												.getAnswersByColor(questionnaireStore.getCurrent(), colorId, this.nameId)
												.map(sectionAnswers => {
													return (
														<div key={sectionAnswers.sectionId}>
															<div className="sectionNameWrapper">
																{i18n.exists(t('questions.' + sectionAnswers.sectionId + '.title')) &&
																	t('questions.' + sectionAnswers.sectionId + '.title')}
															</div>
															{sectionAnswers.questions.map(answer => {
																return (
																	<ListItem key={answer + colorId} className={'color-' + colorId}>
																		<div className="liContainer">{t('questions.' + answer.questionId)}</div>
																		{has(answer, 'followUpAnswer') && answer.followUpAnswer !== '' && (
																			<div className="followUpContainer">
																				<div className="questionText">{t('questions.' + answer.followUpQuestion)}</div>
																				<div>{answer.followUpAnswer}</div>
																			</div>
																		)}
																	</ListItem>
																);
															})}
														</div>
													);
												})}
										</List>
									</div>
								);
						  })}

					{!questionnaireStore.isAdultQuestionnaire() && (
						<div className="openAnswerWrapper">
							<div className="neuvokasTopBarTitle openAnswerList">{t('common.answersToOpenquestions')}</div>
							{openAnswers.map(openAnswerSection => {
								return (
									<div key={openAnswerSection.sectionId}>
										<div className="openQuestionSectionTitle">
											{t('questions.' + openAnswerSection.sectionId + '.title')}
										</div>
										{openAnswerSection.openQuestions.map(answer => {
											return (
												<div key={answer.questionId} className="openAnswerTextWrapper">
													<div className="questionTextWrapper questionItem">{t('questions.' + answer.questionId)}</div>
													<div className="questionTextWrapper questionAnswer">{answer.answer || '-'}</div>
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					)}
					<div className="logoWrapper">
						<img className="logoLeft" src={require('./Neuvokas_ensisijainen_logo1_rgb_c.png')} alt="" />
						<img className="logoRight" src={require('./sl_logo_fi_rgb_c.png')} alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withTranslation()(FeedbackView));
