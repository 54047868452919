import React from 'react';

import './OpenQuestion.scss';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { action } from 'mobx';
import TextField from '@material-ui/core/TextField';

@inject('mobileStore', 'swipeStore', 'questionnaireStore')
@observer
class OpenQuestion extends React.Component {
	@action
	handleChange = e => {
		const { question } = this.props;
		question.answer = e.target.value;
	};

	render() {
		const { questionnaireStore, mobileStore, question, t } = this.props;
		const adultNames = questionnaireStore.getNames();

		return (
			<div className="openQuestion">
				<div
					className={
						mobileStore.isMobile()
							? adultNames.length > 0
								? 'cardContainer neuvokasPaperMobile adult'
								: 'cardContainer neuvokasPaperMobile'
							: 'cardContainer'
					}>
					{mobileStore.isMobile() && <div className="numberWrapper"></div>}
					<div className="openTextAnswer">{t('questions.' + question.questionId)}</div>
					<TextField
						className="openQuestionTextWrapper"
						id="outlined-full-width"
						label="Vastaus"
						style={{ margin: 8 }}
						margin="normal"
						variant="outlined"
						InputLabelProps={
							mobileStore.isMobile()
								? {
										className: 'neuvokasQuestion'
								  }
								: { className: 'neuvokasQuestion' }
						}
						InputProps={{
							className: 'openQuestionInput',
							classes: {
								notchedOutline: 'openQuestionTextWrapper'
							}
						}}
						rows={mobileStore.isMobile() ? '3' : '2'}
						multiline
						onChange={this.handleChange}
						defaultValue={question.answer}
					/>
				</div>
			</div>
		);
	}
}
export default withTranslation()(OpenQuestion);
