import React from 'react';

import { withTranslation } from 'react-i18next';

import './Instructions.scss';

import { observer, inject } from 'mobx-react';
import ColorInfo from '../colorInfo/ColorInfo';

@inject('mobileStore')
@observer
class Instructions extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<div className="instructions">
				<div className="neuvokasText">{t('beginning.introTextFirst')}</div>
				<div className="colorInfoWrapper">
					<ColorInfo />
				</div>

				<div className="neuvokasText">{t('beginning.introTextSecond')}</div>
			</div>
		);
	}
}
export default withTranslation()(Instructions);
